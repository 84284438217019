import React, { Component } from 'react'



import { ProductConsumer } from "../../ContextApi";
import ReactPlayer from 'react-player'
import { NavLink} from "react-router-dom";
let s=''

export default class Fp2 extends Component {
 static contextType = ProductConsumer;

 state=
 {
  TodayPageTimeTwo:"",
  TodayPagedateTwo:""
 }
 componentDidMount=async()=>
 {
  await this.context.dataTodayTwo();
  await this.context.updatePathName(window.location.pathname + window.location.search);
  window.onbeforeunload = async (evt)=> {
     
  
    await this.context.Leavesubmitedata()
    var message = 'Are you sure you want toleave?';
    if (typeof evt == 'undefined') {
     evt.returnValue =" message"
    }       
    if (evt) {     
    evt.returnValue =" message";
    }
    console.log("windiwmessge",evt)
     return message;
  
  
}
window.onunload = async()=> {

  await this.context.Leavesubmitedata()


}

 }


  render() {
    const opts = {
      // height: '390',
      // width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: false,
        controls:0,
      
        loop:0
      }
    };
    return (
      <ProductConsumer>
        {context_data => {
          const {
            affiliate_id,
            handleShow,
            handleClose,
            show,
            OnBufferSecond,
            inputValue,
            OnBuffer,
            played,
            handlePause,
            handleProgress,
            VideoOnPlay,
            VideoonPause,
            sendData,
            videoOnePlay,
            onPlaybackRateChangedata,
            VideoOnwatch,
            NameFunction,
            SendAllData,
            handlePlay,
            playing,
            onstartfun,
            OnseekFun,
            EmailSendcall,
            submitedata,
            Emailsubmitedata,
            handlePlaySecond,
            playingSecond,
            readyfun,
            OnseekFunSecond,
            handleProgressSecond ,
            handlePausesecond,
            
          } = context_data;

      
          return (
            <>
            <div>

            </div>


            </>
               
          );
        }}
      </ProductConsumer>
    );
  }
}



